import '../scss/style.scss';
import { tns } from "tiny-slider/src/tiny-slider";
var Sticky = require('sticky-js');

document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('#home-slider')) {
        var slidert1 = tns({
            container: '#home-slider',
            mouseDrag: true,
            items: 1,
            gutter: 10,
            center: true,
            slideBy: 'page',
            swipeAngle: false,
            speed: 1000,
            autoplayTimeout: 5000,
            autoplay: true,
            slideBy: 1,
            prevButton: false,
            controlsPosition: "bottom",
            navPosition: "bottom",
        });
    }

    var sticky = new Sticky('.sticky-social');

    const searchBtn = document.querySelector('.search-btn');
    const cancel = document.querySelector('.cancel');
    const modalSearch = document.querySelector('.modal-search');

    searchBtn.addEventListener('click', () => {
        modalSearch.classList.remove('hidden');
        modalSearch.classList.add('fadeIn');
        const input = modalSearch.querySelector('input');

        input.focus();
    });

    function closeSearchModal2(event) {
        // Obtener el elemento más profundo en la posición (x, y) del clic
        const clickedElement = document.elementFromPoint(event.clientX, event.clientY);

        const isModal = clickedElement.classList.contains('modal-search');
        const isCancelButton = clickedElement.classList.contains('cancel');

        if (isModal && isMobileDevice()) return;
        
        if (!isModal && !isCancelButton) return;
    
        modalSearch.classList.add('fadeOut');
        setTimeout(() => {
            modalSearch.classList.add('hidden');
            modalSearch.classList.remove('fadeOut');
        }, 500);
    }
    
    cancel.addEventListener('click', closeSearchModal2);
    modalSearch.addEventListener('click', closeSearchModal2);

    const menuBtn = document.querySelector('.nav-mobile');

    const close = document.querySelector('.close');
    const mobilemainmenu = document.querySelector('.mobile-main-menu');

    menuBtn.addEventListener('click', () => {
        mobilemainmenu.classList.remove('md:hidden');
        mobilemainmenu.classList.add('fadeIn2');
    });

    function closeSearchModal() {
        mobilemainmenu.classList.add('fadeOut');
        setTimeout(() => {
            mobilemainmenu.classList.add('md:hidden');
            mobilemainmenu.classList.remove('fadeOut');
        }, 500); 
    }
      
    close.addEventListener('click', closeSearchModal);

    function toggleCategoryModal() {
        var categorymodal = document.querySelector('.navigation-tabs-mobile');
        categorymodal.classList.toggle('md:hidden');
        categorymodal.classList.toggle('fadeIn2');
    }

    var category = document.querySelector('.category-btn');
    category.addEventListener('click', toggleCategoryModal);
});


document.addEventListener('DOMContentLoaded', function() {
    var socialLinks = document.querySelectorAll('.social a.social-link');

    socialLinks.forEach(function(link) {
        console.log('#LK', link.href);
        if (link.href == '#' || link.href == '') return;


        link.addEventListener('click', function(event) {
            event.preventDefault();
            var url = this.href;
            var width = 600;
            var height = 400;
            var left = (screen.width / 2) - (width / 2);
            var top = (screen.height / 2) - (height / 2);

            window.open(url, 'Compartir en redes sociales', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
        });
    });
});


document.addEventListener("DOMContentLoaded", function () {
    const searchForm = document.getElementById("search-form");
    const searchInput = searchForm.querySelector("input[type='text']");
    const searchResults = document.getElementById("search-results");

    if (!searchForm || !searchInput || !searchResults) {
        return;
    }

    const ajaxUrl = searchForm.dataset.ajaxUrl;
    let debounceTimer;

    searchInput.addEventListener("input", function () {
        clearTimeout(debounceTimer);

        const query = searchInput.value;

        searchResults.style.display = "none";

        if (query.length > 2) {
            debounceTimer = setTimeout(() => {
                fetch(ajaxUrl + "?action=search_ajax&query=" + encodeURIComponent(query))
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            throw new Error('Error en la petición AJAX');
                        }
                    })
                    .then(text => {
                        searchResults.innerHTML = text;
                        searchResults.style.display = "block";
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }, 300); // 300 ms de espera
        } else {
            searchResults.innerHTML = "";
        }
    });


const topMenuItem = document.querySelector('#header-menu');
const scrollThreshold = 0;

window.addEventListener('scroll', function() {
  if (window.scrollY > scrollThreshold) {
    topMenuItem.classList.add('menu-reduced');
  } else {
    topMenuItem.classList.remove('menu-reduced');
  }
});

});

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Verificar si es un dispositivo Android
    if (/android/i.test(userAgent)) {
        return true;
    }

    // Verificar si es un dispositivo iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }

    return false;
}